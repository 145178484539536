<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Ishonchnoma registratsiyasi</h3>
            </div>
          </div>
          <div class="card-body">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="4" class="pl-0">
                  <v-autocomplete
                    :error-messages="selectErrors"
                    v-model="select"
                    :items="allTrucks"
                    label="Mashina raqami"
                    :disabled="this.$route.params.id !== undefined"
                    item-text="full__name"
                    item-value="id"
                    outlined
                    dense
                    @input="$v.select.$touch()"
                    @blur="$v.select.$touch()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" class="pl-0">
                  <v-autocomplete
                    :error-messages="select2Errors"
                    v-model="select2"
                    :items="allDriverNames"
                    label="Haydovchi nomi"
                    item-text="full__name"
                    item-value="id"
                    outlined
                    dense
                    @input="$v.select2.$touch()"
                    @blur="$v.select2.$touch()"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4" class="pl-0">
                  <v-text-field
                    dense
                    outlined
                    v-model="start_date"
                    v-mask="'##-##-####'"
                    label="Boshlanish sanasi"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pl-0">
                  <v-text-field
                    dense
                    outlined
                    v-mask="'##-##-####'"
                    v-model="date"
                    label="Tugash sanasi"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <div>
              <b-button
                :disabled="newPermitLoading"
                variant="success"
                class="w-100"
                @click="submit()"
              >
                <i v-if="newPermitLoading" class="el-icon-loading"></i>
                Yuborish</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      newPermitLoading: false,
      date: '',
      select2: '',
      modal: false,
      modal2: false,
      start_date: '',
      reg_number: '',
      given_by: '',
      select: ''
    }
  },
  created() {
    this.$store.dispatch('getTruckLists')
    this.$store.dispatch('getAllDrivers')
  },
  validations: {
    select2: {
      required
    },
    select: {
      required
    },
    date: {
      required
    }
  },
  computed: {
    select2Errors() {
      const errors = []
      if (!this.$v.select2.$dirty) return errors
      !this.$v.select2.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    selectErrors() {
      const errors = []
      if (!this.$v.select.$dirty) return errors
      !this.$v.select.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    dateErrors() {
      const errors = []
      if (!this.$v.date.$dirty) return errors
      !this.$v.date.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    allTrucks() {
      const dataNames = []

      this.$store.state.requests.allTruckList.forEach((element) => {
        const name = {
          full__name: element.reg_number_region + ' ' + element.reg_number,
          id: element.id
        }
        dataNames.push(name)
      })
      return dataNames
    },
    allDriverNames() {
      const data = this.$store.state.requests.allDrivers
      // const data = this.$store.state.requests.allTruckList
      // data.forEach((element) => {
      //   if (element.driver !== null) {
      //     element.full__name =
      //       element.driver.staff_description.staffpersonal_description
      //         .first_name +
      //       ' ' +
      //       element.driver.staff_description.staffpersonal_description.last_name
      //   } else element.full__name = null
      // })
      return data
    }
  },
  filters: {},
  methods: {
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          truck: this.select,
          driver: this.select2,
          expires: this.date.split('-').reverse().join('-')
        }
        this.newPermitLoading = true
        this.$store
          .dispatch('createDrive', {
            data: data,
            page: '1'
          })
          .then(() => {
            this.newPermitLoading = false
            this.date = ''
            this.select2 = ''
            this.select = ''
            this.$v.$reset()
          })
          .catch((err) => {
            this.newPermitLoading = false
            console.error(err)
          })
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.select = parseInt(this.$route.params.id, 10)
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Ishonchnoma registratsiyasi' }
    ])
  }
}
</script>

<style></style>
